import * as React from 'react';
import { useEffect, useState } from 'react';
import { ContentPage } from '../../../types/Content';
import PageContent from '../../../components/PageContent';
import { TGetUserDataData, TSurveysState } from '../../../types/Survey';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store/Store';
import SurveyStart from '../../../components/survey/SurveyStart';
import { TAuthState } from '../../../types/Auth';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  clearPreviousSurveyResponses,
  getAnsweredData,
  getSurvey,
  getSurveyIdentificationInfo,
  getSurveyStructure,
  getUserData,
} from '../../../actions/SurveyActions';
import {
  generateDummyRespondentId,
  useCurrentActiveSurvey,
} from '../../../hooks/useCurrentActiveSurvey';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TDispatch } from '../../../types/Thunk';
import { reverse } from 'named-urls';
import { routes } from '../../../Routes';
import { getCategory, getResumeSurveyInfo } from '../../../helpers/Survey';
import {AxiosError, AxiosResponse} from 'axios';
import {makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  logoImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '35%'
    },
    maxWidth: '15%',
    display: 'flex',
    margin: '15px auto 50px auto'
  },
}));


const SurveyStartPage: React.FC<RouteComponentProps<any>> = (
  props
): JSX.Element => {
  const solutionLogo = `${window.location.origin}/logo`;
  const surveyId = parseInt(props.match.params.id);
  const respondentId = generateDummyRespondentId();
  const surveyState: TSurveysState = useSelector<IAppState, TSurveysState>(
    (state) => state.surveysState
  );
  const authState: TAuthState = useSelector<IAppState, TAuthState>(
    (state) => state.authState
  );
  const dispatch = useDispatch<TDispatch>();
  const classes = useStyles();
  const {
    setRespondent,
    isInspection,
    loadRespondentsStorage,
    setRespondentsStorage,
    getCompletedSurveysStorage,
    setCompletedSurveysStorage,
  } = useCurrentActiveSurvey();
  const [language, setLanguage] = useState(
    surveyState.currentSurvey?.language || ''
  );
  const { t, i18n } = useTranslation();
  const [isResumingAvailable, setIsResumingAvailable] = useState(false);
  const query = new URLSearchParams(props.location.search);
  const accessToken = query.get('access');

  useEffect(() => {
    if (!isEmpty(surveyState)) {
      setLanguage(surveyState.currentSurvey?.language as string);
      setRespondent(
        respondentId,
        surveyId,
        surveyState.currentSurvey?.language || 'da'
      );
      if (surveyState.responses) {
        dispatch(clearPreviousSurveyResponses());
      }
      surveyState.currentSurvey?.survey.availableLanguages.forEach((language) => {
        i18n.loadLanguages(language.language.languageCode);
      });
    }
    //eslint-disable-next-line
  }, [surveyState.currentSurvey, surveyId]);

  useEffect(() => {
    if (
      (!isEmpty(surveyState.currentSurvey) &&
        typeof surveyState.surveySequence !== 'undefined') ||
      surveyState.loading
    )
      return;

    (async () => {
      dispatch(
        getUserData(loadRespondentsStorage(), getCompletedSurveysStorage(), accessToken)
      ).then(async (arg: any) => {
        const res = arg as AxiosResponse<TGetUserDataData>;

        setRespondentsStorage(res.data.incomplete);
        setCompletedSurveysStorage(res.data.completed);
        setIsResumingAvailable(surveyId in res.data.incomplete);

        await dispatch(getSurvey(surveyId, undefined, accessToken));
        try {
          await dispatch(getSurveyStructure(surveyId));
          await dispatch(getSurveyIdentificationInfo(surveyId));
        } catch (e) {
          const error = e as AxiosError;
          if (error.response?.status !== 401) {
            // If the user does not authorize then ok, just pass it;
            throw e;
          }
        }

      });
    })();
    //This should only trigger when we don't have current survey yet and we need to load it.
    //eslint-disable-next-line
  }, [
    surveyState.currentSurvey,
    surveyState.surveySequence,
    surveyState.loading,
    surveyId,
    accessToken
  ]);

  const fetchNewStructure = async (lang: string) => {
    if (lang) {
      if (surveyState.currentSurvey?.language !== lang) {
        await dispatch(getSurvey(surveyId, lang));
        await dispatch(getSurveyStructure(surveyId, lang));
      }
    }
  };

  const changeSurveyLanguage = (lang: string) => {
    setLanguage(lang);
    fetchNewStructure(lang).then(() => {
      setRespondent(respondentId, surveyId, lang);
    });
  };

  const resumeSurvey = () => {
    const respodentsStorage = loadRespondentsStorage();
    const respondentId = respodentsStorage[surveyId];

    dispatch(getAnsweredData(surveyId, respondentId)).then((res: any) => {
      setRespondent(respondentId, surveyId, language);

      const answeredQuestions = res.data.questions;
      const commentedCategories = res.data.categories;

      // If no questions or comments were submitted begin at survey start
      if (answeredQuestions.length === 0 && commentedCategories.length === 0) {
        props.history.push(
          reverse(routes.surveyCategory, {
            id: surveyId
          })
        );

        return;
      }

      const { categoryId, subCategoryId, questionId } = getResumeSurveyInfo(
        surveyState.surveySequence,
        answeredQuestions,
        commentedCategories,
        surveyState.currentSurvey?.survey.comments
      );

      //We have question id and category - going straight to question
      if (categoryId !== 0 && questionId !== 0) {
        const params: Record<string, any> = {
          id: surveyId,
          categoryId: categoryId,
          questionId: questionId,
        };

        if (subCategoryId) {
          params['subCategoryId'] = subCategoryId;
        }

        props.history.push({
          pathname: reverse(routes.surveyQuestion, params),
        });
        return;
      }

      //We don't have question, but comments is enabled, so we are going to category comment page
      if (surveyState.currentSurvey?.survey.comments && categoryId) {
        const currentCategory = getCategory(
          surveyState.surveySequence,
          categoryId
        );
        const nextCategory = getCategory(
          surveyState.surveySequence,
          categoryId,
          true
        );

        let nextSubCategory = undefined;

        if (
          !nextCategory?.questions &&
          nextCategory?.subCategories &&
          nextCategory?.subCategories.length > 0
        ) {
          nextSubCategory = nextCategory?.subCategories[0].data.id;
        }

        props.history.push({
          pathname: reverse(routes.surveyCategoryComment, {
            id: surveyId,
            categoryId: categoryId,
          }),
          state: {
            nextCategory: nextCategory?.data.id,
            nextSubCategory,
            comments: surveyState.currentSurvey?.survey.comments,
            categoryName: currentCategory?.data.title,
          },
        });
        return;
      }

      //No categories, no available questions -> survey finish page
      props.history.push(
        reverse(routes.surveySendAnswersPage, {
          id: surveyId,
        })
      );
    });
  };

  return (
    <>
      {authState.data?.user === null && solutionLogo && (
        <img
          src={solutionLogo}
          className={classes.logoImage}
          alt='logo'
        />
      )}

      <PageContent
        customTitle={
          isInspection ? t('inspection.start.page.title') : undefined
        }
        pageType={ContentPage.SURVEY_START}
        showAsParagraph={true}
      />
      {surveyState.currentSurvey && (
        <SurveyStart
          currentSurvey={surveyState.currentSurvey}
          isResumingAvailable={isResumingAvailable}
          resumeSurvey={resumeSurvey}
          language={language}
          changeSurveyLanguage={changeSurveyLanguage}
          accessToken={accessToken}
        />
      )}
    </>
  );
};

export default withRouter(SurveyStartPage);
