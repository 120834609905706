import * as React from 'react';
import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePhoto, getCaseTypes, reportCase, TReportCaseRequest, uploadPhoto } from '../../../actions/CaseActions';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IAppState } from '../../../store/Store';
import {
  CaseType,
  DeleteCasePhoto,
  ReportCase,
  TApiCasePhoto,
  TCasesState,
  UploadCasePhoto,
} from '../../../types/Cases';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CasePhotoList from '../../../components/cases/CasePhotoList';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TDispatch } from '../../../types/Thunk';
import AlertBlock from '../../../components/AlertBlock';
import { TAuthState } from '../../../types/Auth';
import { useApp } from '../../../hooks/useApp';
import { routes } from '../../../Routes';
import CaseTypeSelect from '../../../components/cases/CaseTypeSelect';
import CasePhotoSelect from '../../../components/cases/CasePhotoSelect';
import { ContentPage } from '../../../types/Content';
import PageContent from '../../../components/PageContent';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { setInspectionQuestion, setInspectionSent } from '../../../actions/InspectionActions';
import { loadStoredRespondentState } from '../../../hooks/useCurrentActiveSurvey';
import { useInspection } from '../../../hooks/useInspection';
import { Alert, Autocomplete, Color } from '@material-ui/lab';
import { useUser } from '../../../hooks/useUser';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import Paragraph from '../../../components/cms/Paragraph';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },
    submit: {
      margin: theme.spacing(3, 0, 3),
    },
    alertContainer: {
      width: '100%',
    },
    personalContainer: {
      border: '2px solid #6FADE7',
      padding: theme.spacing(2),
      width: '100%',
    },
    fileCopy: {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      textAlign: 'right',
    },
  })
);

const CaseReport: React.FC<RouteComponentProps<any>> = ({
  history,
}): JSX.Element => {
  const dispatch = useDispatch<TDispatch>();
  const { app } = useApp();
  const authState: TAuthState = useSelector<IAppState, TAuthState>(
    (state) => state.authState
  );
  const caseState: TCasesState = useSelector<IAppState, TCasesState>(
    (state: IAppState) => state.caseState
  );
  const { inspectionLocationId, isInspectionStored } = useInspection();
  const classes = useStyles();
  const { t } = useTranslation();
  const [type, setType] = useState<undefined | number>(undefined);
  const [workplaceReportUrl, setWorkplaceReportUrl] = useState('');
  const [problem, setProblem] = useState('');
  const [name, setName] = useState(authState.data?.user?.name as string);
  const [nearMissInjuredName, setNearMissInjuredName] = useState('');
  const [nearMissDate, setNearMissDate] = useState(moment());
  const [success, setSuccess] = useState(false);
  const [caseCode, setCaseCode] = useState<any>(null);
  const [errorOccurred, setErrorOccurred] = useState<any>(false);
  const [error, setError] = useState<any>(null);
  const { getUserLocation, getUserDepartment } = useUser();
  const userLocation = getUserLocation();
  const userDepartment = getUserDepartment();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackType, setSnackType] = React.useState<Color>('success');
  const [snackMessage, setSnackMessage] = React.useState('');

  const emptyValue = {
    id: 0,
    label: t('generic.none'),
  };

  const [location, setLocation] = React.useState(emptyValue);
  const [department, setDepartment] = React.useState(emptyValue);

  const [locationsList, setLocationsList] = React.useState([emptyValue]);
  const [departmentsList, setDepartmentsList] = React.useState([emptyValue]);

  useEffect(() => {
    if (userDepartment) {
      setDepartment({
        id: userDepartment.id,
        label: userDepartment?.title,
      });
    }

    let inspectionLocationWasChosen = false;

    if (
      isInspectionStored &&
      inspectionLocationId > 0 &&
      locationsList.length > 0
    ) {
      const chosenLocation = locationsList.find((l) => {
        return l.id === inspectionLocationId;
      });

      if (chosenLocation) {
        setLocation(chosenLocation);
        inspectionLocationWasChosen = true;
      }
    }
    if (!inspectionLocationWasChosen && userLocation) {
      setLocation({
        id: userLocation.id,
        label: userLocation?.title,
      });
    }
  }, [
    userLocation,
    userDepartment,
    isInspectionStored,
    inspectionLocationId,
    locationsList,
  ]);

  useEffect(
    () => () => {
      dispatch(setInspectionQuestion(false));
    },
    //eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (caseState.locations) {
      const arr = Object.entries(caseState.locations).map((element: any) => {
        const value = element[1];
        const obj = value[0];
        const level = value[1];

        return {
          id: obj.entity.id,
          label: '-'.repeat(level) + ' ' + obj.entity.title,
        };
      });

      arr.unshift(emptyValue);
      setLocationsList(arr);
    }

    if (caseState.departments) {
      const arr = Object.entries(caseState.departments).map((element: any) => {
        const value = element[1];
        const obj = value[0];
        const level = value[1];

        return {
          id: obj.entity.id,
          label: '-'.repeat(level) + ' ' + obj.entity.title,
        };
      });

      arr.unshift(emptyValue);
      setDepartmentsList(arr);
    }

    if (caseState.data && caseState.data.length > 0) {
      if (!type) {
        setType(caseState.data[0].type);
      }

      if (!workplaceReportUrl) {
        caseState.data.forEach(function (e) {
          if (e.type === CaseType.WORKPLACE_ACCIDENT && e.reportUrl) {
            setWorkplaceReportUrl(e.reportUrl);
          }
        });
      }
    }
    //eslint-disable-next-line
  }, [
    caseState.data,
    caseState.locations,
    caseState.departments,
    type,
    workplaceReportUrl,
  ]);

  const deleteUploadedPhoto = async (photoId: number) => {
    await dispatch(deletePhoto(photoId)).catch(() => {
      setErrorOccurred(true);
    });
  };

  const handlePhotoUpload = async (fileName: string, base64Content: string) => {
    await dispatch(uploadPhoto(fileName, base64Content)).catch(() => {
      setErrorOccurred(true);
    });
  };

  useEffect(() => {
    if (app.hasError(UploadCasePhoto)) {
      setError(app.getError(UploadCasePhoto));
    }
    if (app.hasError(DeleteCasePhoto)) {
      setError(app.getError(DeleteCasePhoto));
    }
    if (app.hasError(ReportCase)) {
      setError(app.getError(ReportCase));
    }
  }, [app]);

  const isNearMiss = type === CaseType.NEAR_MISS_ACCIDENT;
  const isWhistleblower = type === CaseType.WHISTLEBLOWER;
  const isWorkplaceAccident = type === CaseType.WORKPLACE_ACCIDENT;

  const submitWorkplaceReport = () => {
    const newWindow = window.open(
      workplaceReportUrl,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  const submitCaseReport = async (e: FormEvent) => {
    e.preventDefault();
    const photos: Array<number> = [];
    if (caseState.photos && caseState.photos.length > 0) {
      caseState.photos.forEach((photo: TApiCasePhoto) => {
        return photos.push(photo.id);
      });
    }

    const requestData: TReportCaseRequest = {
      category: type as number,
      problemDescription: problem,
      authorName: isWhistleblower ? 'Anonymous' : name,
      photos,
      nearMissInjuredName: isWhistleblower ? '' : nearMissInjuredName,
      nearMissDate: isWhistleblower ? '' : nearMissDate?.format('YYYY-MM-DD'),
      location: isWhistleblower ? 0 : location.id,
      department: isWhistleblower ? 0 : department.id,
    };

    if (isInspectionStored) {
      const respondentIdentifier = loadStoredRespondentState();
      if (respondentIdentifier) {
        requestData.respondentId = respondentIdentifier.id;
      }
    }

    await dispatch(reportCase(requestData))
      .then((createData) => {
        setProblem('');
        setType(1);
        setError(null);
        setCaseCode(createData.data.code);
        //If we need to go back, we shouldn't display success message here.
        if (isInspectionStored) {
          dispatch(setInspectionSent(true));
          history.goBack();
        } else {
          setSuccess(true);
        }
      })
      .catch(() => {
        setSuccess(false);
        setErrorOccurred(true);
      })
      .finally(() => {
        window.scrollTo(0, 0);
      });
  };

  const fetchCaseTypes = async () => {
    await dispatch(getCaseTypes(authState.settings?.languageCode));
  };

  const copyToClipboard = (code : string) => {
    const whistleblowerLink = `${window.location.origin}${routes.prefix}${routes.whistleblowerCommunication}`;
    navigator.clipboard.writeText(`
      ${t('whistleblower.report.success.link')}
      ${whistleblowerLink}
      ${t('whistleblower.report.success.personal_code')} ${code}
    `).then(
      () => {
        setSnackMessage(t('whistleblower.report.copy_to_clipboard.success'));
        setOpenSnack(true);
      })
      .catch((e) => {
        setSnackType('error');
        setSnackMessage(t('whistleblower.report.copy_to_clipboard.error'));
        setOpenSnack(true);

        throw e;
      });
  }

  useEffect(() => {
    if (authState.settings?.languageCode) {
      fetchCaseTypes();
    }
    //eslint-disable-next-line
  }, [dispatch, authState.settings]);

  useEffect(() => {
    if (!caseState.loading && caseState.data && caseState.data.length < 1) {
      history.push(routes.home);
    }
  });

  return (
    <>
      {success && (
        <AlertBlock type={'success'} onClose={() => setSuccess(false)}>
          {t('case.report.success.alert')}
        </AlertBlock>
      )}
      {success && caseCode && (
        <>
          <AlertBlock type='info'>
            <Paragraph body={t('case.whistleblower.success_message_2')} />
            <p className={classes.personalContainer}>
              <p>
                <b>{t('whistleblower.report.success.link')}</b>:{' '}
                <Link to={routes.whistleblowerCommunication} target='_blank'>
                  {`${window.location.origin}${routes.prefix}${routes.whistleblowerCommunication}`}
                </Link>
              </p>
              <p>
                <b>{t('whistleblower.report.success.personal_code')}</b>:{' '}
                {caseCode}
              </p>
              <p className={classes.fileCopy}>
                <IconButton
                  aria-label='copy'
                  onClick={() => copyToClipboard(caseCode as string)}
                >
                  <FileCopyIcon
                    color="primary"
                  />
                </IconButton>
              </p>
            </p>
          </AlertBlock>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={() => setOpenSnack(false)}
          >
            <Alert severity={snackType} onClose={() => setOpenSnack(false)}>
              {snackMessage}
            </Alert>
          </Snackbar>
        </>
      )}
      {error && errorOccurred && (
        <AlertBlock type={'error'}>{error?.message}</AlertBlock>
      )}

      {caseState.enabled === false && (
        <AlertBlock hideCloseButton={true} type={'warning'}>
          {t('case.report.disabled')}
        </AlertBlock>
      )}

      {caseState.enabled === true && (
        <form onSubmit={submitCaseReport}>
          <PageContent
            pageType={ContentPage.CASES_PAGE}
            showAsParagraph={true}
          />
          <CaseTypeSelect caseState={caseState} type={type} setType={setType} />

          {isWorkplaceAccident ? (
            <>
              <AlertBlock type={'info'} hideCloseButton={true}>
                {t('case.workplace_accident_info')}
              </AlertBlock>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Button
                    type='button'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    onClick={submitWorkplaceReport}
                  >
                    {t('case.report_accident')}
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {isWhistleblower && (
                <AlertBlock type={'info'} hideCloseButton={true}>
                  {t('case.whistleblower_info_1')}
                  <br />
                  <br />
                  {t('case.whistleblower_info_2')}
                </AlertBlock>
              )}

              <CasePhotoSelect handlePhotoUpload={handlePhotoUpload} accept='image/*' />

              {caseState.photos && caseState.photos.length > 0 && (
                <CasePhotoList
                  photos={caseState.photos}
                  deleteUploadedPhoto={deleteUploadedPhoto}
                />
              )}

              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                name='password'
                label={` ${t(
                  isNearMiss
                    ? 'case.near_miss.describe.title'
                    : 'case.problem.label'
                )} *`}
                type='text'
                id='problem'
                multiline
                rows={3}
                value={problem}
                error={!!error?.fields?.problemDescription}
                helperText={
                  error?.fields?.problemDescription ??
                  (isNearMiss ? t('case.near_miss.describe.label') : null)
                }
                onChange={(e) => setProblem(e.target.value)}
              />

              {isNearMiss && (
                <>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label={`${t('case.near_miss.injured_name.title')} *`}
                    type='text'
                    value={nearMissInjuredName}
                    error={!!error?.fields?.nearMissInjuredName}
                    helperText={
                      error?.fields?.nearMissInjuredName ??
                      t('case.near_miss.injured_name.label')
                    }
                    onChange={(e) => setNearMissInjuredName(e.target.value)}
                  />
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      inputVariant='outlined'
                      margin='normal'
                      fullWidth
                      disableFuture
                      format='DD-MM-yyyy'
                      value={nearMissDate}
                      onChange={(e) => {
                        if (e) {
                          setNearMissDate(e);
                        }
                      }}
                      label={`${t('case.near_miss.date.title')} *`}
                      error={!!error?.fields?.nearMissDate}
                      helperText={
                        error?.fields?.nearMissDate ??
                        t('case.near_miss.date.label')
                      }
                      showTodayButton
                    />
                  </MuiPickersUtilsProvider>
                </>
              )}

              {!isWhistleblower && (
                <>
                  <Autocomplete
                    options={locationsList}
                    getOptionLabel={(option) => option.label}
                    value={location}
                    onChange={(event: any, newValue: any) => {
                      setLocation(newValue ?? emptyValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={`${t('case.location.label')}`}
                        error={!!error?.fields?.location}
                        helperText={
                          error?.fields?.location ?? t('case.location.helper')
                        }
                      />
                    )}
                  />

                  <Autocomplete
                    options={departmentsList}
                    getOptionLabel={(option) => option.label}
                    value={department}
                    onChange={(event: any, newValue: any) => {
                      setDepartment(newValue ?? emptyValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label={`${t('case.department.label')}`}
                        error={!!error?.fields?.department}
                        helperText={
                          error?.fields?.department ??
                          t('case.department.helper')
                        }
                      />
                    )}
                  />

                  <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    name='name'
                    label={`${t('case.name.label')} *`}
                    type='text'
                    id='name'
                    value={name}
                    error={!!error?.fields?.authorName}
                    helperText={
                      error?.fields?.authorName ??
                      (isNearMiss ? t('case.near_miss.name.label') : null)
                    }
                    onChange={(e) => setName(e.target.value)}
                  />
                </>
              )}

              <Grid container spacing={1} justifyContent="space-around">
                <Grid item xs={6}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                  >
                    {t('case.report.submit.button')}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </form>
      )}
    </>
  );
};

export default withRouter(CaseReport);
