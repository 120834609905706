import { apiCall } from './BaseAction';
import {
  DeleteCasePhoto,
  GetCaseTypes,
  ReportCase,
  TDeleteCasePhotoAction,
  TGetCaseTypesAction,
  TReportCaseAction,
  TUploadCasePhotoAction,
  UploadCasePhoto,
} from '../types/Cases';
import { TSurveyRespondentId } from '../types/Survey';
import { TClearCasePhotosAction, ClearCasePhotos } from '../types/Cases';
import { Dispatch } from 'redux';

export const getCaseTypes = (lang?: string) => {
  return apiCall<TGetCaseTypesAction>(
    GetCaseTypes,
    'GET',
    '/cases/types',
    true,
    null,
    { lang }
  );
};

export type TPhotoUploadRequestData = {
  filename: string;
  content: string;
};
export const uploadPhoto = (
  filename: string,
  content: string,
  additionalData?: Record<string, string | boolean | number>
) => {
  return apiCall<TUploadCasePhotoAction, TPhotoUploadRequestData>(
    UploadCasePhoto,
    'POST',
    '/cases/photo',
    true,
    { filename, content, ...additionalData }
  );
};

export const deletePhoto = (photoId: number) => {
  return apiCall<TDeleteCasePhotoAction>(
    DeleteCasePhoto,
    'DELETE',
    `/cases/photo/${photoId}`,
    true
  );
};

export type TReportCaseRequest = {
  category: number;
  problemDescription: string;
  authorName: string;
  photos: Array<number>;
  nearMissInjuredName: string;
  nearMissDate: string;
  respondentId?: TSurveyRespondentId;
  location: number;
  department: number;
};

export type TReportCreateResponse = {
  caseId: number;
  code: string | null;
}

export const reportCase = (data: TReportCaseRequest) => {
  return apiCall<TReportCaseAction, TReportCaseRequest, TReportCreateResponse>(
    ReportCase,
    'POST',
    '/cases',
    true,
    { ...data }
  );
};

export const clearPhotos = () => {
  return (dispatch: Dispatch<TClearCasePhotosAction>) => {
    dispatch({
      type: ClearCasePhotos.SUBMIT,
    });
  };
};
