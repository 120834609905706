import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getActionPlan } from '../../../actions/ActionPlanActions';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { TActionPlanState } from '../../../types/Actionplan';
import { IAppState } from '../../../store/Store';
import ActionPlanItem from '../../../components/actionplan/ActionPlanItem';

const ActionPlanPage: React.FC<
  RouteComponentProps<{
    id: string | undefined;
  }>
> = ({
  match: {
    params: { id },
  },
}): JSX.Element => {
  const dispatch = useDispatch();
  const actionPlanState: TActionPlanState = useSelector<
    IAppState,
    TActionPlanState
  >((state) => state.actionPlanState);

  useEffect(() => {
    if (id) {
      dispatch(getActionPlan(parseInt(id)));
    }
  }, [id, dispatch]);

  return (
    <>
      {actionPlanState.activeActionPlan && !actionPlanState.loading && (
        <ActionPlanItem actionPlan={actionPlanState.activeActionPlan} />
      )}
    </>
  );
};

export default withRouter(ActionPlanPage);
