import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getChemicalProduct } from '../../../actions/ChemicalActions';
import { TChemicalState } from '../../../types/Chemical';
import { IAppState } from '../../../store/Store';
import { setCurrentPageTitle } from '../../../actions/ContentActions';
import ChemicalProduct from '../../../components/chemicals/ChemicalProduct';

const ChemicalProductPage: React.FC<
  RouteComponentProps<{
    productId?: string;
  }>
> = ({
  match: {
    params: { productId },
  },
}): JSX.Element => {
  const dispatch = useDispatch();
  const chemicalsState: TChemicalState = useSelector<IAppState, TChemicalState>(
    (state) => state.chemicalState
  );

  useEffect(() => {
    dispatch(getChemicalProduct(parseInt(productId as string)));
  }, [dispatch, productId]);

  useEffect(() => {
    dispatch(
      setCurrentPageTitle(chemicalsState.activeChemical?.data.name as string)
    );
  }, [chemicalsState, dispatch]);

  const [expandedFirstAid, setExpandedFirstAid] = useState(
    !!localStorage.getItem('expandedFirstAid')
  );

  const [expandedHazards, setExpandedHazards] = useState(
    !!localStorage.getItem('expandedHazards')
  );

  const [expandedProducer, setExpandedProducer] = useState(
    !!localStorage.getItem('expandedProducer')
  );

  const [expandedHandling, setExpandedHandling] = useState(
    !!localStorage.getItem('expandedHandling')
  );

  const [expandedRevised, setExpandedRevised] = useState(
    !!localStorage.getItem('expandedRevised')
  );

  const [expandedExposure, setExpandedExposure] = useState(
    !!localStorage.getItem('expandedExposure')
  );

  const [expandedFireFighting, setExpandedFireFighting] = useState(
    !!localStorage.getItem('expandedFireFighting')
  );

  const [expandedSpills, setExpandedSpills] = useState(
    !!localStorage.getItem('expandedSpills')
  );

  const updateLocalStorage = (title: string, isExpanded: boolean) => {
    if (isExpanded) {
      localStorage.setItem(title, String(isExpanded));
    } else {
      localStorage.removeItem(title);
    }
  }

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      switch (panel) {
        case 'panel2':
          setExpandedFirstAid(isExpanded);
          updateLocalStorage('expandedFirstAid', isExpanded);
          break;
        case 'panel3':
          setExpandedHazards(isExpanded);
          updateLocalStorage('expandedHazards', isExpanded);
          break;
        case 'panel4':
          setExpandedProducer(isExpanded);
          updateLocalStorage('expandedProducer', isExpanded);
          break;
        case 'panel5':
          setExpandedHandling(isExpanded);
          updateLocalStorage('expandedHandling', isExpanded);
          break;
        case 'panel6':
          setExpandedRevised(isExpanded);
          updateLocalStorage('expandedRevised', isExpanded);
          break;
        case 'panel7':
          setExpandedExposure(isExpanded);
          updateLocalStorage('expandedExposure', isExpanded);
          break;
        case 'panel8':
          setExpandedFireFighting(isExpanded);
          updateLocalStorage('expandedFireFighting', isExpanded);
          break;
        case 'panel9':
          setExpandedSpills(isExpanded);
          updateLocalStorage('expandedSpills', isExpanded);
          break;
      }
    };

  return (
    <>
      {chemicalsState.activeChemical && !chemicalsState.loading && (
        <ChemicalProduct
          chemical={chemicalsState}
          expandedFirstAid={expandedFirstAid}
          expandedHazards={expandedHazards}
          expandedProducer={expandedProducer}
          expandedRevised={expandedRevised}
          expandedHandling={expandedHandling}
          expandedExposure={expandedExposure}
          expandedFireFighting={expandedFireFighting}
          expandedSpills={expandedSpills}
          handleChange={handleChange}
        />
      )}
    </>
  );
};

export default withRouter(ChemicalProductPage);
