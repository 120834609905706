import { Reducer } from 'redux';
import {
  ActionPlan,
  ActionPlanCategories,
  ActionPlanList,
  initialActionPlanState,
  TActionPlanActions,
  TActionPlanState,
} from '../types/Actionplan';
import { endReducer } from '../store/Store';

export const actionPlanReducer: Reducer<
  TActionPlanState,
  TActionPlanActions
> = (state = initialActionPlanState, action) => {
  switch (action.type) {
    case ActionPlanCategories.SUCCESS: {
      return {
        ...state,
        categories: action.payload.data.categories,
      };
    }

    case ActionPlanCategories.FAILED:
      return state;

    case ActionPlanList.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.plans,
      };
    }

    case ActionPlanList.FAILED:
      return state;

    case ActionPlan.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ActionPlan.SUCCESS: {
      return {
        ...state,
        activeActionPlan: action.payload.data.plan,
        loading: false,
      };
    }

    case ActionPlan.FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return endReducer(state, action);
  }
};
